<template>
  <div>
    <div class="p-w-navbar" style="height: 100px">
      <div class="p-w-container p-w-container--default">
        <div class="p-w-container__inner p-w-navbar__inner-container">
          <div style="width: 20%; display: flex; justify-content: flex-start">
            <router-link to="/" style="width: 280px">
              <common-img
                url="icon_dentrionwhitelogo"
                width="105.13px"
                height="27.97px"
                class="text-18"
              />
            </router-link>
          </div>
          <div class="p-w-navbar__content">
            <ul class="p-w-navbar__menu">
              <router-link to="/introduce" class="underline-none">
                <div class="d-flex justify-center align-center">
                  <div class="justify-center align-center">
                    <div class="p-w-navbar__item">
                      <div class="d-flex justify-center align-center">
                        <div
                          class="justify-center align-center text-center"
                          style="width: 73px"
                        >
                          <p class="cursor">Introduce</p>
                          <div
                            v-if="$route.path === '/introduce'"
                            class="text-center white p-absolute"
                            style="
                              width: 73px;
                              height: 2px;
                              margin-top: 35px;
                              z-index: 1;
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link to="/solutions" class="underline-none">
                <li class="p-w-navbar__item">
                  <div class="d-flex justify-center align-center">
                    <div
                      class="justify-center align-center cursor text-center"
                      style="width: 73px"
                    >
                      <p>Why</p>
                      <div
                        v-if="$route.path === '/solutions'"
                        class="text-center white p-absolute"
                        style="
                          width: 73px;
                          height: 2px;
                          margin-top: 35px;
                          z-index: 1;
                        "
                      ></div>
                    </div>
                  </div>
                </li>
              </router-link>

              <router-link to="/products/CROWN" class="underline-none">
                <li class="p-w-navbar__item">
                  <div class="text-center cursor" style="width: 73px">
                    <div class="text-center" style="width: 73px">Products</div>
                  </div>
                </li>
              </router-link>
              <router-link to="/devices" class="underline-none">
                <li class="p-w-navbar__item">
                  <div class="text-center" style="width: 73px">Devices</div>
                </li>
              </router-link>
            </ul>
          </div>
          <div style="width: 20%; display: flex; justify-content: flex-end">
            <div
              class="d-flex justify-space-between align-center p-w-navbar__item"
              style="width: 272px; height: 24px; cursor: pointer"
            >
              <router-link to="/contact">
                <div class="header_apply">제휴 문의</div>
              </router-link>
              <div class="bar"></div>
              <a
                class="underline-none"
                href="https://dentrion.ai/"
                target="_blank"
              >
                <div class="header_login gray--text">전자 의뢰</div>
              </a>
              <a
                class="underline-none"
                href="https://www.instagram.com/dentrion_official/"
                target="_blank"
              >
                <img src="../assets/images/instagram.png" alt="insta_logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-relative"
        style="width: 100%; height: 0.5px; background-color: #e5e5e5"
      ></div>
    </div>
  </div>
</template>
<script>
import CommonImg from "./commons/CommonImg.vue";

export default {
  components: { CommonImg },
  data() {
    return {
      clickedPage: null,
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped>
.p-w-navbar {
  z-index: 50;
  width: 100%;
  height: var(--navbar-height);
  border-bottom: 1px solid transparent;
  background-color: #fff;
  background-color: var(--white);
  position: fixed;
}
.p-w-container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  height: 100%;
}

.p-w-container--default > .p-w-container__inner {
  width: 100%;
  max-width: calc(100% - 48px);
  max-width: calc(100% - var(--padding-container-base));
}
.p-w-navbar__inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 250px;
}
.p-w-container__inner {
  height: 100%;
  align-items: center;
  display: flex;
}
.p-w-navbar__content {
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}
.p-w-navbar__menu {
  display: flex;
  align-items: center;
}

.p-w-navbar__item > a {
  padding: 12px 10px;
  line-height: 20px;
  color: currentColor;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  text-align: left;
  z-index: 999;

  height: 30px;
  display: flex;
  align-items: center;
}
.p-w-navbar__item {
  padding: 0 18px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  list-style-type: none;
}
.header_apply {
  padding-right: 8%;
  text-align: start;
  letter-spacing: -0.45px;
  font-size: 18px;
}
.bar {
  width: 1px;
  background: white;
  height: 24px;
}
.header_login {
  font-size: 18px;

  color: white;
  text-align: end;
  letter-spacing: -0.45px;
}
</style>
