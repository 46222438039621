<template>
  <div
    style="height: 427px; margin: 0 auto"
    class="black align-center mobile-ui font-notosans"
  >
    <div class="d-flex align-center" style="height: 100%">
      <div class="align-center ml-6">
        <common-image
          url="icon_dentrionfooterlogo"
          width="162px"
          height="22px"
        />
        <div class="white--text">
          <div class="text-12 mt40 d-flex flex-column" style="gap: 6px">
            <p>대표이사 정일도</p>
            <p>사업자등록번호 604-85-60729</p>
            <p class="big">
              (21330) 인천광역시 부평구 갈산동 94 (인천테크노밸리U1센터) A동
              703~707호
            </p>
            <p class="small">
              (21330) 인천광역시 부평구 갈산동 94 (인천테크노밸리U1센터) <br />
              A동 703~707호
            </p>
            <p>TEL. 032 545 2804 / 032 541 5703</p>
            <p>Fax. 032 541 5704</p>
            <p>E-mail. dentrion@dentrion.co.kr</p>
          </div>
          <a
            href="https://kr.object.ncloudstorage.com/dentrion-resource/policy/dentrion_privacy_policy2.html"
            style="text-decoration-color: white"
            target="_blank"
          >
            <p class="text-14 mt40 white--text">개인정보처리방침</p>
          </a>
          <p class="text-12 mt20 gray--text underline">
            Copyright ⓒdentrion Inc. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonImage from "../commons/CommonImage.vue";

export default {
  components: { CommonImage },
};
</script>
<style>
.mobile-ui {
  width: 100%;
}
.small {
  display: none;
}
@media screen and (max-width: 470px) {
  .small {
    display: block;
  }
  .big {
    display: none;
  }
}
@media (min-width: 601px) {
  .mobile-container-ui {
    display: flex;
    justify-content: center;
  }
  .mobile-inner-container-ui {
    justify-content: center;
  }
  .mobile-ui {
    width: 600px;
  }
}
</style>
