import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import store from "./store";
import api from "./api";
import axios from "axios";
import VueGtag from "vue-gtag";

gsap.registerPlugin(ScrollTrigger);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-Z1BPLE0J1R",
      params: {
        send_page_view: false,
      },
    },
  },
  router
);

const vm = new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
window.app = vm;
