<template>
  <div>
    <div style="width: 100%">
      <div style="min-width: 1400px">
        <dentrion-header />
        <dentrion-white-header v-if="isShowWhiteHeader" />
        <FloatingMenu />

        <section style="height: 200vh; position: relative">
          <div class="home-subtext-container">
            <common-img
              url="icon_dentrionvideologo"
              width="220px"
              height="58px"
              class="text-18"
            />
            <div class="home-subtext">
              <div
                class="text-56 font-notosans"
                style="font-weight: 600; margin: 40px 0 0 0"
              >
                <p>진정한 디지털</p>
                <p>치과 기공소 덴트리온</p>
              </div>
            </div>
          </div>

          <div class="video-container" id="video-container">
            <video
              playsinline
              autoplay
              muted
              loop
              id="bgvideo"
              class="video-specific-container"
            >
              <source
                src="@/assets/videos/video_homemain.mp4"
                type="video/mp4"
              />
            </video>
            <div class="text-overlay">
              <div
                class="container font-notosans"
                v-for="(image, index) in images"
                :key="index"
              >
                <common-image
                  class="center-image"
                  :url="image.url"
                  :data-index="image.index"
                />
              </div>
            </div>

            <div class="mouse_scroll">
              <div class="mouse">
                <common-img url="icon_scroll" />
              </div>
              <div style="margin-left: 3px; margin-top: 10px">
                <span class="m_scroll_arrows unu"></span>
                <span class="m_scroll_arrows doi"></span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
        </section>

        <section
          style="width: 100%; margin-top: 200px; height: 3606px"
          class="white p-relative"
        >
          <div class="d-flex justify-space-between" style="width: 100%">
            <div
              class="d-flex justify-space-between"
              style="width: 100%; gap: 220px"
            >
              <div
                class="left"
                style="margin: 437px 0 0 220px; white-space: nowrap"
              >
                <div class="special_bgr_text font-montserrat">Special</div>
                <p class="text-36 bold">덴트리온만의 특별함</p>
                <div class="text-24 gray--text" style="margin-top: 80px">
                  <div>
                    <p>덴트리온은 최상의 치과 보철물을 만들기 위해</p>
                    <p>끊임없이 노력합니다.</p>
                  </div>
                  <div class="mt-6">
                    <p>최첨단 기계설비와 청결한 제조환경.</p>
                    <p>덴트리온이 신뢰받는 이유입니다.</p>
                  </div>
                </div>
              </div>
              <div class="right">
                <div
                  v-for="(item, index) in home"
                  :key="index"
                  style="display: flex; flex-direction: column"
                  class="slider_contents"
                >
                  <common-image
                    :url="item.img"
                    :height="item.height"
                    :width="item.width"
                    class="home_special_img"
                  />
                  <div class="home_text">
                    <p class="home_title">{{ item.title }}</p>
                    <div class="home_subtext">
                      <p class="home_subtext1">{{ item.subtext1 }}</p>
                      <p class="home_subtext2">{{ item.subtext2 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="half-container font-notosans"
          id="section-h-2"
          style="margin-top: 20%"
        >
          <div class="right-brg">
            <div
              class="d-flex align-center justify-center right-brg-area"
              style="height: 100%"
            >
              <div
                class="d-flex align-center justify-center"
                style="width: 50%"
              >
                <div
                  class="d-flex justify-content-between"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div style="padding-right: 50px">
                    <common-image
                      url="img_home_FreeSample"
                      width="200px"
                      height="200px"
                    />
                    <div class="text-center">
                      <p
                        class="text-24 lightbold"
                        style="margin-top: 56px; white-space: nowrap"
                      >
                        샘플 테스트
                      </p>
                      <div
                        class="gray--text"
                        style="margin-top: 20px; white-space: nowrap"
                      >
                        <p>보철물 무료 샘플</p>
                        <p>2 Cases</p>
                      </div>
                      <router-link to="/freesample" class="underline-none">
                        <div class="d-flex justify-center cursor ml-4">
                          <div
                            class="d-flex align-center justify-space-between"
                            style="width: 140px; margin-top: 40px"
                          >
                            <p class="text-18" style="color: #1f9dff">
                              샘플 테스트 신청
                            </p>
                            <common-img
                              url="btn_rightarrow"
                              width="8px"
                              height="16px"
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div style="padding-left: 50px">
                    <common-image
                      url="img_home_GoodPrice1"
                      width="200px"
                      height="200px"
                      style="position: relative"
                    />
                    <common-image
                      url="img_home_GoodPrice2"
                      width="51px"
                      style="position: absolute; top: 19%; right: 7%"
                    />
                    <div class="text-center">
                      <p
                        class="text-24 lightbold"
                        style="margin-top: 56px; white-space: nowrap"
                      >
                        최적의 가격
                      </p>
                      <div class="gray--text" style="margin-top: 20px">
                        <p>고품질 보철물을</p>
                        <p>최적의 가격으로 제공</p>
                      </div>
                      <router-link to="/customtable" class="underline-none">
                        <div class="d-flex justify-center cursor ml-4">
                          <div
                            class="d-flex align-center justify-space-between"
                            style="width: 110px; margin-top: 40px"
                          >
                            <p
                              class="text-18"
                              style="color: #1f9dff; white-space: nowrap"
                            >
                              수가표 문의
                            </p>
                            <common-img
                              url="btn_rightarrow"
                              width="8px"
                              height="16px"
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="left-brg2">
            <div class="opasity_box"></div>
            <div class="p-absolute">
              <div
                class="white--text"
                data-aos="fade-up"
                data-aos-duration="1000"
                style="margin-top: 50px"
              >
                <div class="text-36 bold" style="text-align: end">
                  <p>믿을 수 있는 보철물,</p>
                  <p>그리고 최적의 가격</p>
                </div>
                <div
                  class="mt-6 text-18"
                  style="margin-bottom: 124px; text-align: end"
                >
                  <p>전국 디지털 치과 기공소 기준이 되기 위해</p>
                  <p>우리는 혁신을 통해 최고의 제품과 서비스를 제공합니다.</p>
                </div>
                <a
                  href="https://kr.object.ncloudstorage.com/dentrion-resource/%EB%8D%B4%ED%8A%B8%EB%A6%AC%EC%98%A8%20%ED%9A%8C%EC%82%AC%EC%86%8C%EA%B0%9C%EC%84%9C.pdf"
                  download
                  target="_blank"
                  class="underline-none white--text"
                >
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="half-container font-notosans" id="section-h-2">
          <div class="left-brg">
            <div class="p-absolute">
              <div
                class="white--text"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div class="text-36 bold">
                  <p>숙련된 인력과 기술,</p>
                  <p>그리고 파트너와 함께하는 공간</p>
                </div>
                <div class="mt-6 text-18" style="margin-bottom: 124px">
                  <p>1990년부터 이어온 장인정신과 최첨단 설비를 바탕으로</p>
                  <p>디지털 기공소의 혁신을 만들어갑니다.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="right-brg">
            <div
              class="d-flex align-center justify-center right-brg-area"
              style="height: 100%"
            >
              <div
                class="d-flex align-center justify-center"
                style="width: 50%"
              >
                <div
                  class="d-flex justify-content-between"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div style="padding-right: 50px">
                    <common-image
                      url="img_homedevices"
                      width="200px"
                      height="200px"
                    />
                    <div class="text-center">
                      <p
                        class="text-24 lightbold"
                        style="margin-top: 56px; white-space: nowrap"
                      >
                        정밀도 50㎛ 이하
                      </p>
                      <div
                        class="gray--text"
                        style="margin-top: 20px; white-space: nowrap"
                      >
                        <p>최신식 high-end 장비 구비로</p>
                        <p>오차없는 제품 퀄리티</p>
                      </div>
                      <router-link to="/devices" class="underline-none">
                        <div class="d-flex justify-center cursor ml-4">
                          <div
                            class="d-flex align-center justify-space-between"
                            style="width: 120px; margin-top: 40px"
                          >
                            <p class="text-18" style="color: #1f9dff">
                              장비 보러가기
                            </p>
                            <common-img
                              url="btn_rightarrow"
                              width="8px"
                              height="16px"
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div style="padding-left: 50px">
                    <common-image
                      url="img_hometooth"
                      width="200px"
                      height="200px"
                    />
                    <div class="text-center">
                      <p
                        class="text-24 lightbold"
                        style="margin-top: 56px; white-space: nowrap"
                      >
                        정확도 97% 이상
                      </p>
                      <div class="gray--text" style="margin-top: 20px">
                        <p>파트별 전문 장인들이</p>
                        <p>최고의 제품을 제공</p>
                      </div>
                      <router-link to="/products/CROWN" class="underline-none">
                        <div class="d-flex justify-center cursor ml-4">
                          <div
                            class="d-flex align-center justify-space-between"
                            style="width: 120px; margin-top: 40px"
                          >
                            <p class="text-18" style="color: #1f9dff">
                              제품 보러가기
                            </p>
                            <common-img
                              url="btn_rightarrow"
                              width="8px"
                              height="16px"
                            />
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="half-container font-notosans" id="section-h-2">
          <div class="right-brg2">
            <div
              class="d-flex align-center justify-center right-brg-area"
              style="height: 100%"
            >
              <div
                class="d-flex align-center justify-center"
                style="width: 50%"
              >
                <div class="d-flex" data-aos="fade-up" data-aos-duration="1000">
                  <div>
                    <div style="white-space: nowrap" class="font-montserrat">
                      <p class="gray--text text-24">Partner Dental Clinics</p>
                      <p
                        class="text-36 font-medium-montserrat"
                        style="margin-top: 28px"
                      >
                        진정한 디지털 전환 (DX) <br />
                        치과기공소, 덴트리온
                      </p>
                      <div
                        class="gray--text text-18 font-light-bold-montserrat"
                        style="
                          margin-top: 48px;
                          white-space: nowrap;
                          line-height: 150%;
                          letter-spacing: -0.45px;
                        "
                      >
                        <p>
                          치과 산업의 디지털화를 목표로<br />
                          차별화된 제품과 서비스를 제공하기 위해 달려온 시간,
                        </p>
                        <p>프리미엄 디지털 치과 기공소 ‘덴트리온' 입니다.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="homevideo_container">
            <video
              playsinline
              autoplay
              muted
              loop
              id="bgvideo"
              class="homevideo"
            >
              <source src="@/assets/videos/home_video.mp4" type="video/mp4" />
            </video>
          </div>
        </section>
        <section class="half-container font-notosans" id="section-h-2">
          <div class="right-brg3">
            <div
              class="d-flex align-center justify-center right-brg-area"
              style="height: 100%"
            >
              <div
                class="d-flex align-center justify-center"
                style="width: 50%"
              >
                <div class="d-flex" data-aos="fade-up" data-aos-duration="1000">
                  <div>
                    <div style="white-space: nowrap" class="font-montserrat">
                      <p class="gray--text text-24">Partner Dental Clinics</p>
                      <p
                        class="text-36 font-medium-montserrat"
                        style="margin-top: 28px"
                      >
                        전국 각지의 병·의원과 <br />
                        거래하는 덴트리온
                      </p>
                      <div
                        class="gray--text text-18 font-light-bold-montserrat"
                        style="
                          margin-top: 48px;
                          white-space: nowrap;
                          line-height: 150%;
                          letter-spacing: -0.45px;
                        "
                      >
                        <p>전국 각지의 치과 병·의원을 비롯하여</p>
                        <p>
                          다수의 대학병원과 국립의료기관에서도 덴트리온을
                          사용하고 있습니다.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="homeGIF_container">
            <img
              src="../assets/videos/new_dentrion_map.gif"
              alt=""
              class="dentrion_map"
            />
          </div>
        </section>
      </div>
    </div>
    <DentrionFooter />
  </div>
</template>

<script>
import DentrionHeader from "@/components/DentrionHeader.vue";
import CommonImage from "@/components/commons/CommonImage.vue";
import CommonImg from "@/components/commons/CommonImg.vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AOS from "aos";

import "aos/dist/aos.css";
import DentrionWhiteHeader from "@/components/DentrionWhiteHeader.vue";
import DentrionFooter from "@/components/DentrionFooter.vue";
import FloatingMenu from "@/components/FloatingMenu.vue";
gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    DentrionHeader,
    CommonImage,
    CommonImg,
    DentrionWhiteHeader,
    DentrionFooter,
    FloatingMenu,
  },

  data() {
    return {
      videoUrl: "@/assets/videos/video_homemain.mp4",
      videoType: "video/mp4",
      isMobile: false,
      isTablet: false,
      isShowWhiteHeader: false,
      isShowMobileWhiteHeader: false,

      images: [
        { url: "logo_Den", index: 1 },
        { url: "img_TRI", index: 2 },
        { url: "img_ON", index: 3 },
      ],
      home: [
        {
          img: "DX_image",
          height: "430px",
          width: "648px",
          title: "DX 덴탈랩",
          subtext1: "자체 개발한 MES / ERP 시스템을 사용하여",
          subtext2: "의뢰부터 정산, 재고 관리, 배송까지 모든 과정의 전산화",
        },
        {
          img: "CNC_image",
          height: "430px",
          width: "648px",
          title: "CNC 정밀 가공",
          subtext1: "최첨단 CNC 장비를 통해",
          subtext2: "디자인 그대로 오차없는 Custom abutment",
        },

        {
          img: "img_homeenvironment1",
          height: "430px",
          width: "648px",
          title: "최첨단 High-end 장비",
          subtext1: "각 파트별 High-end 라인업 장비로",
          subtext2: "오차없는 최적의 보철물 제작",
        },
        {
          img: "img_homeenvironment4",
          height: "430px",
          width: "648px",
          title: "직원 성장 교육",
          subtext1: "모든 업무에서 완벽을 추구하기 위한",
          subtext2: "덴트리온만의 노하우를 전수하는 직원 교육 세미나 개최",
        },
      ],
    };
  },
  computed: {},
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > 2200) {
        this.isShowWhiteHeader = true;
      } else {
        this.isShowWhiteHeader = false;
      }
    },
    setupScrollTrigger() {
      const containers = this.$el.querySelectorAll(".container");

      containers.forEach((container) => {
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: container,
            pin: true,
            scrub: true,
            markers: false,
          },
        });
        tl.to(container, {
          autoAlpha: 1,
          ease: "power2.inOut",
        }).to(container, {
          autoAlpha: 0,
          ease: "power2.inOut",
        });
      });
    },
  },
  created() {
    const isMobile = this.$store.state.isMobile;
    if (isMobile) {
      this.$router.push("/mobile-home");
    }
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set(".home-subtext-container", { opacity: 1 });
    const homeMain = document.querySelectorAll(".home-subtext-container");
    homeMain.forEach((element) => {
      gsap.to(element, {
        opacity: 0,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: element,
          start: 1,
          end: 1,
          toggleActions: "play none none reverse",
          scrub: true,
          markers: false,
        },
      });
    });

    const video = document.getElementById("video-container");

    const globalTimeline2 = gsap.timeline({
      scrollTrigger: {
        trigger: video,
        start: "top top",
        end: "150% top",
        pin: true,
        scrub: true,
        invalidateOnRefresh: true,
        markers: false,
      },
    });

    globalTimeline2.add(this.setupScrollTrigger());

    window.addEventListener("scroll", this.handleScroll);

    AOS.init();
  },
  beforeDestroy() {
    ScrollTrigger.getAll().forEach((trigger) => {
      trigger.kill();
    });
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
.container,
.spacer {
  visibility: hidden;
  width: 100%;
}
.text-overlay {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.spacer {
  visibility: visible;
}
.half-container {
  display: flex;
  width: 100%;
  height: 998px;
}

.left-brg2 {
  position: relative;
  flex: 1;
  background-image: url("@/assets/images/img_homefirst.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homevideo_container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homevideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeGIF_container {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
.dentrion_map {
  width: 700px;
  object-fit: cover;
}
.opasity_box {
  position: absolute;
  background: #0c0c0c;
  width: 100%;
  height: 100%;
  right: 0;
  opacity: 0.6;
}

.left-brg {
  position: relative;
  flex: 1;
  background-image: url("@/assets/images/img_homesecond.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-brg,
.right-brg2,
.right-brg3 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-brg {
  background-color: #f8f8f8;
}
.right-brg2 {
  background-color: white;
}
.right-brg3 {
  background-color: #f5f5f5;
}

.right-brg-area {
  width: 50%;
}

.mouse_scroll2 {
  display: block;
  position: absolute;
  bottom: 10%;
  right: 50%;
  width: 24px;
  padding-top: 800px;
}

.mouse2 {
  width: 30px;
  height: 40px;
}

.m_scroll_arrows2 {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 2px solid #7c7c7c;
  border-bottom: 2px solid #7c7c7c;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}
.left {
  position: sticky;
  height: 500px;
  top: 136px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
}

.right {
  margin: 540px 150px 0 0;
}
.home_special_img {
  margin-top: 200px;
}
.slider_contents {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.home_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
}

.home_title {
  color: #000;
  font-family: "Noto Sans KR";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.8px;
}

.home_subtext {
  color: var(--sub-text, #7c7c7c);
  font-family: "Noto Sans KR";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
  letter-spacing: -0.5px;
  margin-top: 16px;
}

.special_container {
  display: flex;
  width: 100%;
}
.special_main_content {
  margin-top: 300px;
  z-index: 1;
  text-align: center;
}

.video-container {
  position: relative;
  height: 100vh;
  margin: 0 auto;
  z-index: 1;
}
.video-specific-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.home-subtext-container {
  position: absolute;
  bottom: 57%;
  left: 220px;
  z-index: 999;
}
.home-subtext {
  width: 100%;
  line-height: 1.4;
  color: white;
  min-width: 300px;
  z-index: 999;
}

.special_bgr_text {
  font-size: 200px;
  font-weight: 600;
  color: #f5f5f5;
  position: absolute;
  top: 5%;
  left: -20%;
  z-index: -1;
}
.center-image {
  display: block;
  margin: 0 auto;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.mouse_scroll {
  display: block;
  position: absolute;
  bottom: 3%;
  right: 50%;
  width: 24px;
  padding-top: 800px;
}

.mouse {
  width: 30px;
  height: 40px;
}

.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.unu {
  margin-top: 1px;
}

.unu,
.doi {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.section5 {
  background-image: url("@/assets/images/img_homeFooter.svg");
  width: 100%;
  height: 530px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: bottom;
  padding-top: 40px;
}

.contact_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 41px;
  border-radius: 24px;
  border: 1px solid var(--main, #51c37e);
  background: var(--white, #fff);
  width: 120px;
  height: 46px;
  color: #51c37e;
  cursor: pointer;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@-o-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
