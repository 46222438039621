<template>
  <div class="mobile-container-ui">
    <div class="mobile-inner-container-ui">
      <div class="mobile-ui">
        <div>
          <mobile-dentrion-header />
          <mobile-dentrion-white-header v-if="isShowWhiteHeader" />

          <FloatingMenu v-if="floatingDisplay" class="floatingMenu" />

          <section>
            <div class="p-relative" style="height: 100vh; width: 100%">
              <div class="video-container" id="video-container">
                <video
                  playsinline
                  autoplay
                  muted
                  loop
                  id="bgvideo"
                  class="video-mobile-specific-container"
                >
                  <source
                    src="@/assets/videos/video_mobilehomemain.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="mouse_scroll">
                <div class="mouse_container">
                  <div class="mouse">
                    <common-img url="icon_scroll" />
                  </div>
                  <div style="margin-left: 3px; margin-top: 10px">
                    <span class="m_scroll_arrows unu"></span>
                    <span class="m_scroll_arrows doi"></span>
                  </div>
                </div>
              </div>
              <div class="p-container__contents p-container__contents--default">
                <div class="p-container__contents__inner">
                  <div
                    class="mobile-text-overlay d-flex flex-column justify-center"
                  >
                    <common-img
                      url="icon_dentrionvideologo"
                      width="120px"
                      height="auto"
                      style="margin: 0 auto"
                    />
                    <div
                      class="white--text bold text-24 font-notosans mt10 letter-06"
                    >
                      <p>진정한 디지털</p>
                      <p>치과 기공소 덴트리온</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style="position: relative">
            <common-img
              url="img_mobilehomeviewsecond"
              height="524px"
              style="filter: brightness(40%)"
            />
            <div class="d-flex">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                class="p-absolute white--text font-notosans"
                style="top: 128px; left: 5%"
              >
                <div class="bold text-24 letter-06">
                  <p>믿을 수 있는 보철물,</p>
                  <p>그리고 최적의 가격</p>
                </div>
                <div class="medium-text text-14 mt-6">
                  <p>전국 디지털 치과 기공소 기준이 되기 위해</p>
                  <p>우리는 혁신을 통해 최고의 제품과 서비스를 제공합니다.</p>
                </div>
                <div class="small-text text-14 mt-6">
                  <p>전국 디지털 치과 기공소 기준이 되기 위해</p>
                  <p>우리는 혁신을 통해 최고의 제품과</p>
                  <p>서비스를 제공합니다.</p>
                </div>
              </div>
            </div>
          </section>

          <section style="padding: 100px 0" class="lightgray">
            <div class="d-flex" style="height: 100%">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                class="lightgray-slide font-notosans"
                style="width: 100%"
              >
                <div
                  class="text-center align-center justify-center"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <common-img
                    url="img_homesample"
                    width="120px"
                    height="120px"
                  />
                  <p class="text-18 medium mt35 bold letter-045">샘플 테스트</p>
                  <div
                    class="text-14 gray--text mt25 letter-035"
                    style="white-space: nowrap"
                  >
                    <p>보철물 무료 샘플</p>
                    <p>2 Cases</p>
                  </div>
                  <router-link to="/mobile-freesample" class="underline-none">
                    <div class="d-flex align-center justify-space-between mt45">
                      <p class="text-14 blue--text mr-2 letter-035">
                        샘플 테스트 신청
                      </p>
                      <common-img
                        url="btn_rightarrow"
                        width="7px"
                        height="12px"
                      />
                    </div>
                  </router-link>
                </div>

                <div
                  class="text-center align-center justify-center"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <common-img
                    url="img_homeprice"
                    width="120px"
                    height="120px"
                  />
                  <p class="text-18 medium mt35 bold letter-045">최적의 가격</p>
                  <div
                    class="text-14 gray--text mt25 letter-035"
                    style="white-space: nowrap"
                  >
                    <p>고품질 보철물을</p>
                    <p>최적의 가격으로 제공</p>
                  </div>
                  <router-link to="/mobile-customtable" class="underline-none">
                    <div class="d-flex align-center justify-space-between mt45">
                      <p class="text-14 blue--text mr-2 letter-035">
                        수가표 문의
                      </p>
                      <common-img
                        url="btn_rightarrow"
                        width="7px"
                        height="12px"
                      />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </section>

          <section style="position: relative">
            <common-image
              url="img_mthirdhome"
              height="514px"
              style="filter: brightness(40%)"
            />
            <div class="d-flex">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                class="p-absolute white--text font-notosans"
                style="top: 128px; left: 5%"
              >
                <div class="bold text-24 letter-06">
                  <p>숙련된 인력과 기술,</p>
                  <p>그리고 파트너와 함께하는 공간</p>
                </div>
                <div class="medium-text text-14 mt-6">
                  <p>1990년부터 이어온 장인정신과 최첨단 설비를 바탕으로</p>
                  <p>파트너와 함께 디지털 기공소의 혁신을 만들어갑니다.</p>
                </div>
                <div class="small-text text-14 mt-6">
                  <p>1990년부터 이어온 장인정신과</p>
                  <p>최첨단 설비를 바탕으로</p>
                  <p>디지털 기공소의 혁신을 만들어갑니다.</p>
                </div>
              </div>
            </div>
          </section>

          <section style="padding: 100px 0" class="lightgray">
            <div class="d-flex" style="height: 100%">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                class="lightgray-slide font-notosans"
                style="width: 100%"
              >
                <div
                  class="text-center align-center justify-center"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <common-image
                    url="img_homedevices"
                    width="120px"
                    height="120px"
                  />
                  <p class="text-18 medium mt35 bold letter-045">
                    정밀도 50㎛ 이하
                  </p>
                  <div
                    class="text-14 gray--text mt25 letter-035"
                    style="white-space: nowrap"
                  >
                    <p>최신식 high-end 장비 구비로</p>
                    <p>오차없는 제품 퀄리티</p>
                  </div>
                  <router-link to="/mobile-devices" class="underline-none">
                    <div class="d-flex align-center justify-space-between mt45">
                      <p class="text-14 blue--text mr-2 letter-035">
                        장비 보러가기
                      </p>
                      <common-img
                        url="btn_rightarrow"
                        width="7px"
                        height="12px"
                      />
                    </div>
                  </router-link>
                </div>

                <div
                  class="text-center align-center justify-center"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <common-image
                    url="img_hometooth"
                    width="120px"
                    height="120px"
                  />
                  <p class="text-18 medium mt35 bold letter-045">
                    정확도 97% 이상
                  </p>
                  <div
                    class="text-14 gray--text mt25 letter-035"
                    style="white-space: nowrap"
                  >
                    <p>파트별 전문 장인들이</p>
                    <p>최고의 제품을 제공</p>
                  </div>
                  <router-link
                    to="/mobile-products/CROWN"
                    class="underline-none"
                  >
                    <div class="d-flex align-center justify-space-between mt45">
                      <p class="text-14 blue--text mr-2 letter-035">
                        제품 보러가기
                      </p>
                      <common-img
                        url="btn_rightarrow"
                        width="7px"
                        height="12px"
                      />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </section>

          <section class="white font-notosans">
            <div>
              <div
                data-aos-duration="2000"
                style="width: 100%"
                class="second-mobile-height"
              >
                <div
                  class="p-container__contents p-container__contents--default"
                >
                  <div
                    class="p-container__contents__inner d-flex align-center"
                    :style="width < 480 ? 'width: 100%' : null"
                  >
                    <div
                      class="image-container d-flex justify-center align-center p-relative"
                      style="width: 100%; position: relative"
                    >
                      <common-image
                        class="img_flexible p-relative"
                        url="img_homemobilesecond"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                        :height="width >= 480 ? '320px' : '360px'"
                      />
                      <div
                        class="text-center d-flex"
                        style="
                          position: absolute;
                          z-index: 1;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        "
                      >
                        <div
                          data-aos-duration="1000"
                          data-aos="fade-up"
                          class="align-center justify-center white--text"
                          style="white-space: nowrap"
                        >
                          <p class="medium-text bold text-24 letter-06">
                            진정한 디지털 치과 기공소 덴트리온
                          </p>
                          <div class="small-text bold text-24 letter-06">
                            <p>진정한 디지털 치과 기공소</p>
                            <p>덴트리온</p>
                          </div>
                          <div class="text-14 mt-8 letter-035">
                            <p>치과 산업의 디지털화를 목표로</p>
                            <p>
                              차별화된 제품과 서비스를 제공하기 위해 달려온
                              시간,
                            </p>
                            <p>
                              프리미엄 디지털 치과 기공소 '덴트리온' 입니다.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="fifth-mobile-height p-relative">
              <div class="d-flex justify-center">
                <div class="d-flex justify-center">
                  <div
                    class="medium-text bgr-mobile-gray-text letter-5"
                    style="top: 80px; left: 50%; transform: translateX(-50%)"
                  >
                    Special
                  </div>
                  <div
                    class="small-text letter-5 p-absolute font-montserrat"
                    style="
                      top: 56px;
                      font-size: 60px;
                      color: #f5f5f5;
                      font-weight: 600;
                      left: 50%;
                      transform: translateX(-50%);
                    "
                  >
                    Special
                  </div>
                </div>
                <div
                  class="p-container__contents p-container__contents--default"
                >
                  <div class="p-container__contents__inner special-section">
                    <div class="font-notosans">
                      <div
                        class="p-relative special-text"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <p class="bold text-24 letter-06">
                          덴트리온만의 특별함
                        </p>
                        <div class="gray--text mt-8 text-14 letter-035">
                          <div>
                            <p>덴트리온은 최상의 치과 보철물을 만들기 위해</p>
                            <p>끊임없이 노력합니다.</p>
                          </div>
                          <div class="mt-4">
                            <p>최첨단 기계설비와 청결한 제조환경.</p>
                            <p>덴트리온이 신뢰받는 이유입니다.</p>
                          </div>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000">
                        <div style="margin-top: 50px">
                          <common-image
                            url="DX_image"
                            class="img_flexible"
                            width="552px"
                          />
                          <div class="special-text">
                            <p class="text-18 bold mt-6 letter-045">
                              DX 덴탈랩
                            </p>
                            <div
                              class="mt-5 gray--text text-14 mobile-sub-text"
                            >
                              <p>자체 개발한 MES / ERP 시스템을 개발하여</p>
                              <p>
                                의뢰부터 정산, 재고 관리, 배송까지 모든 과정의
                                전산화
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000">
                        <div style="margin-top: 56px">
                          <common-image url="CNC_image" class="img_flexible" />
                          <div class="special-text">
                            <p class="text-18 bold mt-6 letter-045">
                              CNC 정밀 가공
                            </p>
                            <div
                              class="mt-5 gray--text mobile-sub-text text-14 letter-035"
                            >
                              <p>최첨단 CNC 장비를 통해</p>
                              <p>디자인 그대로 오차없는 Custom abutmnet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000">
                        <div style="margin-top: 56px">
                          <common-image
                            url="img_homeenvironment1"
                            class="img_flexible"
                          />
                          <div class="special-text">
                            <p class="text-18 bold mt-6 letter-045">
                              최첨단 High-end 장비
                            </p>
                            <div
                              class="mt-5 gray--text mobile-sub-text text-14"
                            >
                              <p>각 파트별 High-end 라인업 장비로</p>
                              <p>오차없는 최적의 보철물 제작</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="1500">
                        <div style="margin-top: 56px">
                          <common-image
                            url="img_homeenvironment4"
                            class="img_flexible"
                          />
                          <div class="special-text">
                            <p class="text-18 bold mt-6 letter-045">
                              직원 성장 교육
                            </p>
                            <div
                              class="mt-5 gray--text mobile-sub-text text-14"
                            >
                              <p>모든 업무에서 완벽을 추구하기 위한</p>
                              <p>
                                덴트리온만의 노하우를 전수하는 직원 교육 세미나
                                개최
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="sixth-mobile-height">
            <div
              class="p-container__contents p-container__contents--default p-relative"
            >
              <div class="p-container__contents__inner" style="width: 100%">
                <div>
                  <div class="d-flex justify-center">
                    <div
                      class="medium-text p-absolute letter-5 bgr-mobile-white-text"
                      style="top: 65px; left: 50%; transform: translateX(-50%)"
                    >
                      Innovations
                    </div>
                    <div
                      class="small-text p-absolute letter-5 font-montserrat"
                      style="
                        font-size: 60px;
                        font-weight: 600;
                        color: white;
                        top: 45px;
                        left: 50%;
                        transform: translateX(-50%);
                      "
                    >
                      Innovations
                    </div>
                  </div>
                  <div
                    style="position: relative; z-index: 2; padding-left: 24px"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div
                      class="medium-text mobile-real-main-text text-24 bold font-notosans"
                      style="padding-top: 150px"
                    >
                      <p>치과 병·의원과 환자를 위한</p>
                      <p>혁신적인 디지털 덴탈 랩 컨버젼스</p>
                    </div>
                    <div
                      class="small-text mobile-real-main-text text-24 bold font-notosans letter-06"
                      style="padding-top: 100px"
                    >
                      <p>치과 병·의원과 환자를 위한</p>
                      <p>혁신적인 디지털</p>
                      <p>덴탈 랩 컨버젼스</p>
                    </div>
                    <div
                      class="medium-text mobile-text text-14 gray--text mt-6 font-notosans letter-07"
                    >
                      <p>
                        수 없이 쌓은 경험과 오랜 고민을 바탕으로 최상의 제품을
                        제공하기 위한 품질 혁신과 시스템 혁신을
                      </p>
                      <p>
                        이루었습니다. 덴트리온이 꿈꾸는 '진정한 디지털 텐탈랩'
                        바로 이곳에서 실현됩니다.
                      </p>
                    </div>
                    <div
                      class="small-text mobile-text text-14 gray--text mt-6 font-notosans letter-07"
                    >
                      <p>
                        수 없이 쌓은 경험과 오랜 고민을 바탕으로 최상의 제품을
                      </p>
                      <p>
                        제공하기 위한 품질 혁신과 시스템 혁신을 이루었습니다.
                      </p>
                      <p>덴트리온이 꿈꾸는 '진정한 디지털 텐탈랩'</p>
                      <p>바로 이곳에서 실현됩니다.</p>
                    </div>
                  </div>

                  <div style="margin-top: 83px">
                    <div class="swiper">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <common-image
                            url="img_homemobileinnovations1"
                            width="366px"
                            class="img_swiper_flexible"
                          />
                        </div>
                        <div class="swiper-slide">
                          <common-image
                            url="img_homemobileinnovations2"
                            width="366px"
                            class="img_swiper_flexible"
                          />
                        </div>
                        <div class="swiper-slide">
                          <common-image
                            url="img_homemobileinnovations3"
                            width="366px"
                            height="auto"
                            class="img_swiper_flexible"
                          />
                        </div>
                        <div class="swiper-slide">
                          <common-image
                            url="img_homemobileinnovations4"
                            width="366px"
                            class="img_swiper_flexible"
                          />
                        </div>
                        <div class="swiper-slide">
                          <common-image
                            url="img_homemobileinnovations5"
                            width="366px"
                            class="img_swiper_flexible"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="p-relative" style="height: 311px">
            <div>
              <common-img url="img_homeFooter" width="100%" height="311px" />
              <div class="d-flex">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  class="text-center p-absolute align-center justify-center"
                  style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
                >
                  <div
                    class="font-bold-montserrat text-24"
                    style="font-weight: 700"
                  >
                    <p>Affliate Patner</p>
                    <p>Recruitment</p>
                  </div>
                  <div
                    class="mt-3 font-notosans text-14 letter-035"
                    style="line-height: 150%; white-space: nowrap"
                  >
                    <p>치과 산업의 내일을 함께 견인할</p>
                    <p>최고의 비즈니스 파트너를 모십니다.</p>
                  </div>
                  <div class="mt-6 d-flex flex-center">
                    <router-link to="/mobile-contact" class="inquire-btn"
                      >제휴 문의</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <mobile-dentrion-footer />
    </div>
  </div>
</template>
<script>
import MobileDentrionHeader from "@/components/mobile/MobileDentrionHeader.vue";
import MobileDentrionWhiteHeader from "@/components/mobile/MobileDentrionWhiteHeader.vue";
import MobileDentrionFooter from "@/components/mobile/MobileDentrionFooter.vue";
import CommonImage from "@/components/commons/CommonImage.vue";
import CommonImg from "@/components/commons/CommonImg.vue";
import FloatingMenu from "@/components/FloatingMenu.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { debounce } from "lodash";

export default {
  components: {
    CommonImage,
    CommonImg,
    MobileDentrionHeader,
    MobileDentrionWhiteHeader,
    MobileDentrionFooter,
    FloatingMenu,
  },
  data() {
    return {
      isShowWhiteHeader: false,
      width: 0,
      swiper: null,

      floatingDisplay: false,
    };
  },
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > window.innerHeight - 600) {
        this.floatingDisplay = true;
      } else {
        this.floatingDisplay = false;
      }

      if (scrollTop > window.innerHeight - 60) {
        this.isShowWhiteHeader = true;
      } else {
        this.isShowWhiteHeader = false;
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    debounceHandleResize: debounce(function () {
      this.handleResize();
    }, 200),
  },

  mounted() {
    this.swiper = new Swiper(".swiper", {
      slidesPerView: 1.5,
      spaceBetween: 10,
      loop: false,
      allowTouchMove: true,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,
    });

    window.addEventListener("scroll", this.handleScroll);
    this.handleResize();
    window.addEventListener("resize", this.debounceHandleResize);
    AOS.init();
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.debounceHandleResize);
  },
};
</script>
<style scoped>
.swiper {
  overflow: hidden;
}
.video-container {
  position: relative;
  height: 100vh;
  margin: 0 auto;
}
.video-mobile-specific-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: brightness(80%);
}

.mobile-text-overlay {
  position: absolute;
  bottom: calc((25 / 136) * 100%);
  left: 50%;
  transform: translate(-50%, -50%);
  animation: opacity-in 1.5s forwards;
  text-align: center;
  white-space: nowrap;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.mouse_scroll {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
}
.mouse_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center-image {
  display: block;
  margin: 0 auto;
}
.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin-right: 3px;
  margin-bottom: 3px;
  width: 16px;
  height: 16px;
}

.unu {
  margin-top: 1px;
}

.unu,
.doi {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.mouse {
  width: 28px;
  height: 40px;
}
.img_flexible {
  width: 100%;
  height: auto;
}
.inquire-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 96px;
  height: 42px;
  border-radius: 24px;
  border: 1px solid var(--main, #51c37e);
  background: var(--white, #fff);
  color: var(--main, #51c37e);
  font-size: 12px;
  font-weight: 500;
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.p-container__contents--default > .p-container__contents__inner {
  width: 92%;
  max-width: calc(100% - 48px);
  max-width: calc(100% - var(--padding-container-base));
}
.p-container__contents__inner {
  height: 100%;
  margin: 0 auto;
}
.p-container__contents--default {
  max-width: 1140px;
}
.p-container__contents {
  width: 100%;
  height: 100%;
  margin: auto;
}
.small-text {
  letter-spacing: -0.35px;
}
.medium-text {
  letter-spacing: -0.35px;
}
.sixth-mobile-height {
  background-color: #f5f5f5;
  padding-bottom: 105px;
}

@media only screen and (min-width: 601px) {
  .mobile-container-ui {
    display: flex;
    justify-content: center;
  }
  .mobile-inner-container-ui {
    justify-content: center;
  }
  .mobile-ui {
    width: 600px;
  }
  .lightgray-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 116px;
  }
  .medium-text {
    display: block;
  }
  .small-text {
    display: none;
  }
  .image-container {
    margin: 96px 0;
    height: 320px;
  }
  .special-section {
    margin-top: 160px;
  }
  .sixth-mobile-height {
    margin-top: 32px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 600px) {
  .mobile-ui {
    width: 100%;
    overflow-x: hidden;
  }
  .lightgray-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 116px;
  }
  .medium-text {
    display: block;
  }
  .small-text {
    display: none;
  }
  .image-container {
    margin: 96px 0;
    height: 320px;
  }
  .special-section {
    margin-top: 160pxg;
  }
  .sixth-mobile-height {
    margin-top: 32px;
  }
}
@media screen and (min-width: 360px) and (max-width: 479px) {
  .p-container__contents--default > .p-container__contents__inner {
    width: calc((13 / 15) * 100%);
  }
  .mobile-ui {
    width: 100%;
    overflow-x: hidden;
  }
  .lightgray-slide {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .medium-text {
    display: none;
  }
  .small-text {
    display: block;
  }
  .special-section {
    margin-top: 111px;
    width: 100% !important;
  }
  .special-text {
    margin-left: 24px;
  }
  .sixth-mobile-height {
    margin-top: 55px;
  }
}
</style>
