import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";
import AES256 from "./aes256.js";
// import { http } from "./http";

// const qs = require("query-string");

const config = {
  baseUrl: process.env.VUE_APP_LOCAL_URL,
  // baseUrl: "http://localhost:9506",
  // baseUrl: 'http://49.50.164.116:9506',
  //baseUrl: 'http://api.dentrium.ai',
  hometaxUrl:
    "https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index.xml",
  imageViewUrl: "/file/displayImage",
};

const headers = {
  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
};

const headersJson = {
  "Content-Type": "application/json; charset=UTF-8",
};

const headerMultipart = {
  "Content-Type": "multipart/form-data",
};

// axios 인스턴스를 생성합니다.
const instance = axios.create({
  baseURL: config.baseUrl,
  //timeout: 1000
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  // paramsSerializer: (params) => {
  //   return qs.stringify(params, { arrayFormat: "brackets" });
  // },
});

instance.interceptors.response.use(
  async (response) => {
    // 검증 필요
    if (response.data.errCode === 104) {
      //이 부분에서 갱신 해주면 됨

      const result = await accessTokenRefresh();

      if (result.data.success) {
        response.config.params.set("token", result.headers.accesstoken);
        store.commit("setAccessToken", result.headers.accesstoken);

        return instance(response.config);
      }
    } else if (response.data.errCode === 109) {
      //중복로그인

      if (!alert("다른 기기에서 로그인하여 로그인 화면으로 이동합니다")) {
        store.commit("setLogin", false);
        router.replace("/");

        //리턴 수정해주세요
        return Promise.reject("");
      }
    } else {
      return response;
    }
  },

  function (error) {
    return Promise.reject(error);
  }
);

function getHometaxUrl() {
  return config.hometaxUrl;
}

function accessTokenRefresh() {
  const params = new URLSearchParams();
  params.append("refreshToken", store.state.apiRefreshToken);
  params.append("userPid", store.state.userinfo.userPid);

  // return instance({
  //     url: "/user/accessTokenRefresh",
  //     method: 'post',
  //     headers: headers,
  //     params: params
  // })

  return axios.post(config.baseUrl + "/user/accessTokenRefresh", params, {
    headers,
  });
}

function login(id, pw) {
  let ua = navigator.userAgent;
  var os = "ETC";
  if (ua.indexOf("Windows") != -1) {
    os = "WINDOWS";
  } else if (ua.indexOf("Mac") != -1) {
    os = "MAC";
  } else {
    os = "ETC";
  }

  return axios.post(
    config.baseUrl + "/user/login",
    { id: id, pw: AES256.encrypt(String(pw)), os: os },
    { headersJson }
  );
}

function logout() {
  let ua = navigator.userAgent;
  var os = "ETC";
  if (ua.indexOf("Windows") != -1) {
    os = "WINDOWS";
  } else if (ua.indexOf("Mac") != -1) {
    os = "MAC";
  } else {
    os = "ETC";
  }

  const params = new URLSearchParams();
  params.append("token", store.state.apiAccessToken);
  params.append("userPid", store.state.userinfo.userPid);
  params.append("os", os);

  return axios.post(config.baseUrl + "/user/logout", params, { headers });
}

function getUserInfo() {
  var params = new URLSearchParams();
  params.append("token", store.state.apiAccessToken);

  return instance({
    url: "/user/getUserInfo",
    method: "post",
    headers: headers,
    params: params,
  });
}

// 아이디 중복 확인
function duplicateIdCheck(id) {
  var params = {
    id: id,
  };
  return instance({
    url: "/user/getUserIdValidation",
    method: "post",
    headers: headersJson,
    params: params,
  });
}

// 파일 업로드
function uploadFile(param) {
  const formData = new FormData();
  formData.append("file", param.file);

  return instance.post("api/file/" + param.path, formData, {
    headers: headerMultipart,
  });
}

function duplicateNumberCheck(businessNumber) {
  var params = {
    businessNumber: businessNumber,
  };
  return instance({
    url: "/api/user/business-duplicate",
    method: "post",
    headers: headersJson,
    params: params,
  });
}

function SignUpSuccess(param) {
  return instance({
    url: "/api/user/factory",
    method: "post",
    headers: headersJson,
    data: param,
  });
}

//문의하기
async function sendContact(
  companyType,
  category,
  name,
  companyName,
  contactNumber,
  email,
  contents
) {
  try {
    const response = await instance.post(
      "/api/contact",
      {
        companyType,
        category,
        name,
        companyName,
        contactNumber,
        email,
        contents,
      },
      { headers: headersJson }
    );

    return response;
  } catch (error) {
    console.error("sendContact error", error);
  }
}

//맞춤 수가표 신청
async function sendPrice(
  name,
  companyName,
  contactNumber,
  email,
  contactType,
  contents
) {
  try {
    const response = await instance.post(
      "/api/requests/price",
      {
        name,
        companyName,
        contactNumber,
        email,
        contactType,
        contents,
      },
      { headers: headersJson }
    );

    return response;
  } catch (error) {
    console.error("sendPrice error", error);
  }
}

//샘플 신청
async function sendSample(
  name,
  companyName,
  contactNumber,
  address,
  prosthesisType,
  impressionType,
  contactType
) {
  try {
    const response = await instance.post(
      "/api/requests/sample",
      {
        name,
        companyName,
        contactNumber,
        address,
        prosthesisType,
        impressionType,
        contactType,
      },
      {
        headers: headersJson,
      }
    );

    return response;
  } catch (err) {
    console.error("sendSample error", err);
  }
}
function getCategoryList(category) {
  return new Promise((resolve, reject) => {
    instance
      .get(`api/requests/code/${category}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getTypeList() {
  return new Promise((resolve, reject) => {
    instance
      .get(`api/contact/type`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export default {
  // 아이디 중복 확인
  duplicateIdCheck,
  uploadFile,
  duplicateNumberCheck,
  SignUpSuccess,
  getHometaxUrl,
  login,
  logout,
  getUserInfo,
  sendContact,
  sendPrice,
  sendSample,
  getCategoryList,
  getTypeList,
};
