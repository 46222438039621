import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import MobileHomeView from "../views/mobile/MobileHomeView.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    window.location.reload();
    window.scrollTo(0, 0);
  });
};

Vue.use(VueRouter);

const validProductTypes = [
  "CROWN",
  "RAMINATE",
  "BRACES",
  "DENTURE",
  "TMPTOOTH",
  "IMPLANT",
];

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../views/MainView.vue"),
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("../views/IntroduceView.vue"),
  },
  {
    path: "/devices",
    name: "devices",
    component: () => import("../views/DevicesView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/customtable",
    name: "customtable",
    component: () => import("../views/CustomtableView.vue"),
  },
  {
    path: "/freesample",
    name: "freesample",
    component: () => import("../views/FreesampleView.vue"),
  },
  {
    path: "/products/:type",
    name: "products",
    component: () => import("../views/ProductsView.vue"),
    beforeEnter: (to, from, next) => {
      if (validProductTypes.includes(to.params.type.toUpperCase())) {
        next();
      } else {
        console.error("올바르지 않은 페이지입니다");
        next({ name: "404" });
      }
    },
  },
  {
    path: "/solutions",
    name: "solutions",
    component: () => import("../views/SolutionsView.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFoundView.vue"),
  },
  {
    path: "/mobile-404",
    name: "mobileNotfound",
    component: () => import("../views/mobile/MobileNotFoundView.vue"),
  },
  {
    path: "/mobile-home",
    name: "mobileHome",
    component: MobileHomeView,
  },
  {
    path: "/mobile-introduce",
    name: "mobileIntroduce",
    component: () => import("../views/mobile/MobileIntroduceView.vue"),
  },
  {
    path: "/mobile-devices",
    name: "mobileDevices",
    component: () => import("../views/mobile/MobileDevicesView.vue"),
  },
  {
    path: "/mobile-products/:type",
    name: "mobileProducts",
    component: () => import("../views/mobile/MobileProductsView.vue"),
    beforeEnter: (to, from, next) => {
      if (validProductTypes.includes(to.params.type.toUpperCase())) {
        next();
      } else {
        console.error("올바르지 않은 페이지입니다");
        next({ name: "404" });
      }
    },
  },
  {
    path: "/mobile-solutions",
    name: "mobileSolutions",
    component: () => import("../views/mobile/MobileSolutionsView.vue"),
  },
  {
    path: "/mobile-contact",
    name: "mobileContact",
    component: () => import("../views/mobile/MobileContactView.vue"),
  },
  {
    path: "/mobile-freesample",
    name: "mobileFreesample",
    component: () => import("../views/mobile/MobileFreeSampleView.vue"),
  },
  {
    path: "/mobile-customtable",
    name: "mobileCustomtable",
    component: () => import("../views/mobile/MobileCustomTableView.vue"),
  },

  {
    path: "*",
    name: "not-found",
    beforeEnter: (to, from, next) => {
      console.error("올바르지 않은 페이지입니다");
      next({ name: "404" });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
