<template>
  <div style="width: 100%">
    <div style="min-width: 1400px">
      <div
        style="width: 100%; height: 550px"
        class="black white--text d-flex align-center justify-center text-align-start font-notosans"
      >
        <div style="padding-top: 80px; padding-right: 600px">
          <div>
            <common-img
              url="icon_dentrionfooterlogo"
              width="177px"
              height="24px"
            />
          </div>
          <p class="pt-10">대표이사 정일도</p>
          <p class="pt-2">사업자등록번호 604-85-60729</p>
          <p class="pt-2">
            (21330) 인천광역시 부평구 갈산동 94(인천테크노벨리U1센터)A동
            703~707호
          </p>
          <p class="pt-2">TEL. 032 545 2804 / 032 541 5703</p>
          <p class="pt-2">Fax. 032 541 5704</p>
          <p class="pt-2">E-mail. dentrion@dentrion.co.kr</p>
          <div style="width: 118px">
            <a
              href="https://kr.object.ncloudstorage.com/dentrion-resource/policy/dentrion_privacy_policy2.html"
              style="text-decoration-color: white"
              target="_blank"
            >
              <p class="white--text cursor mt-8" style="white-space: nowrap">
                개인정보처리방침
              </p>
            </a>
          </div>
          <p class="pt-4" style="margin-bottom: 100px; color: #7a7a7a">
            Copyright ⓒdentrion Inc. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonImg from "./commons/CommonImg.vue";

export default {
  components: { CommonImg },
};
</script>
<style></style>
