<template>
  <div>
    <!-- growAnimation이 true일 때만 grow 애니메이션 실행 -->
    <div
      :class="[
        'home_btn_container',
        { 'grow-animation': growAnimation },
        'font-notosans',
      ]"
    >
      <!-- 수가 문의 버튼 -->
      <router-link
        :to="isMobile ? '/mobile-customtable' : '/customtable'"
        class="underline-none"
      >
        <div class="change_btn_router1">
          <common-img url="btn_home_first" style="width: 48px" />
          <p class="cursor">수가 문의</p>
        </div>
      </router-link>

      <!-- 샘플 신청 버튼 -->
      <router-link
        :to="isMobile ? '/mobile-freesample' : '/freesample'"
        class="underline-none"
      >
        <div class="change_btn_router2">
          <common-img url="btn_home_second" style="width: 48px" />
          <p class="cursor">샘플 신청</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import CommonImg from "@/components/commons/CommonImg.vue";

import "aos/dist/aos.css";

export default {
  components: {
    CommonImg,
  },
  props: {},
  data() {
    return {
      isMobile: false, // 모바일 여부를 저장
      growAnimation: false, // grow 애니메이션 실행 여부
    };
  },
  created() {
    this.isMobile = this.detectMobileDevice(); // 컴포넌트 생성 시 모바일 감지
    this.checkRouteForAnimation(); // 현재 경로 확인하여 애니메이션 설정
  },
  watch: {
    // $route 객체를 감시하여 경로 변경 시 애니메이션 설정
    $route() {
      this.checkRouteForAnimation();
    },
  },
  methods: {
    detectMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|j2me|midp|mobile|palm|phone|pocket|psp|symbian|treo|up.browser|up.link|webos|wos/i.test(
        userAgent
      );
    },
    checkRouteForAnimation() {
      // 현재 경로가 /mobile-home인 경우에만 애니메이션 실행
      if (this.$route.path === "/mobile-home") {
        this.growAnimation = true;
      } else {
        this.growAnimation = false;
      }
    },
  },
};
</script>

<style>
.home_btn_container {
  position: fixed;
  right: 5%;
  top: 78%;
  z-index: 999;
}

/* grow 애니메이션을 관리하는 클래스 */

.grow-animation {
  animation: fade-in 1s linear;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.change_btn_router1,
.change_btn_router2 {
  border-radius: 24px;
  width: 130px;
  height: 48px;
  margin-top: 16px;
  font-size: 16px;
  letter-spacing: -0.4px;
  font-weight: 500;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
  animation: bounce2 10s ease infinite;
  z-index: 1;
}
.btn_home_first {
  z-index: 10;
}
.btn_home_second {
  z-index: 10;
}

.change_btn_router1 {
  background-color: #51c37e;
}

.change_btn_router2 {
  background-color: #087f5b;
}

@keyframes bounce2 {
  0%,
  1%,
  100% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(-10px);
  }
  6% {
    transform: translateY(-5px);
  }
}
</style>
