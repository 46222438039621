<template>
  <div>
    <div class="p-w-navbar" style="height: 60px">
      <div class="p-w-container p-w-container--default">
        <div class="p-w-container__inner p-w-navbar__inner-container">
          <router-link to="/mobile-home" class="link-element">
            <common-img url="icon_dentrionwhitelogo" width="100%" />
          </router-link>
          <common-img
            url="btn_dropmenu"
            width="48px"
            height="48px"
            @click="toggleMyNav"
            style="cursor: pointer"
          />
        </div>
      </div>
      <div class="white" style="width: 100%; height: 0.5px"></div>

      <div
        id="myNav"
        class="overlay"
        v-if="showMyNav"
        @wheel.prevent="preventScroll"
      >
        <div
          class="p-w-container d-flex align-center justify-space-between"
          style="height: 60px"
        >
          <router-link to="/mobile-home" class="link-element">
            <common-img url="icon_dentrionwhitelogo" width="100%" />
          </router-link>
          <div
            class="white--text d-flex align-center"
            @click="toggleMyNav"
            style="height: 13px"
          >
            <common-image url="btn_menuwhiteclose" width="48px" height="48px" />
          </div>
        </div>
        <div class="overlay-content">
          <router-link to="/mobile-introduce" class="underline-none"
            ><p class="mb-6 text-32">Introduce</p></router-link
          >
          <router-link to="/mobile-solutions" class="underline-none"
            ><p class="mb-6 text-32">Why</p></router-link
          >
          <router-link to="/mobile-products/CROWN" class="underline-none"
            ><p class="mb-6 text-32">Products</p></router-link
          >

          <router-link to="/mobile-devices" class="underline-none"
            ><p class="mb-6 text-32">Devices</p></router-link
          >
          <router-link to="/mobile-customtable" class="underline-none"
            ><p class="mb-6 text-32">수가표 문의</p></router-link
          >
          <router-link to="/mobile-freesample" class="underline-none"
            ><p class="mb-6 text-32">샘플 테스트 신청</p></router-link
          >
          <router-link to="/mobile-contact" class="underline-none"
            ><p class="mb-6 text-32">제휴 문의</p></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonImage from "../commons/CommonImage.vue";
import CommonImg from "../commons/CommonImg.vue";

export default {
  components: { CommonImg, CommonImage },
  data() {
    return {
      showMyNav: false,
    };
  },
  methods: {
    toggleMyNav() {
      this.showMyNav = !this.showMyNav;
    },
    preventScroll(event) {
      event.preventDefault();
    },
  },
};
</script>
<style scoped>
.p-w-navbar {
  z-index: 9999;
  width: 100vw;
  height: var(--navbar-height);
  border-bottom: 1px solid transparent;
  background-color: var(--white);
  position: fixed;
}
.p-w-container {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 24px;
}
.p-w-container--default > .p-w-container__inner {
  width: 100%;
  max-width: calc(100% - 48px);
  max-width: calc(100% - var(--padding-container-base));
}
.p-w-navbar__inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.p-w-container__inner {
  height: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
}
.p-w-navbar__content {
  display: flex;
  flex: 1;
  max-height: 100%;
}
.p-w-navbar__menu {
  display: flex;
  align-items: center;
  margin-left: 100px;
  padding: 0;
  margin: 0;
  margin-left: 350px;
}
.p-w-navbar__item {
  padding: 0 18px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  list-style-type: none;
}
.p-w-navbar__item > a {
  padding: 12px 10px;
  font-size: 15px;
  line-height: 20px;
  color: currentColor;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  text-align: left;
  z-index: 999;
}
.overlay {
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: black;
  overflow: hidden;
}

.overlay-content {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: start;
  margin-left: 32px;
  margin-top: 59px;
  background-image: url("@/assets/images/img_menubgrbridge.png");
  background-position: center; /* 가로 중앙 정렬 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover; /* 이미지 크기 조절 */
}

.overlay a {
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
  transition: 0.3s;
  font-weight: 500;
}
.overlay a p {
  cursor: pointer;
}
.overlay a p:hover {
  opacity: 0.4;
}
.overlay .closebtn {
  position: absolute;
  color: white;
  z-index: 9999;
}
@media screen and (min-width: 360px) and (max-width: 479px) {
  .link-element {
    display: block;
    width: 75px;
  }
}
@media (min-width: 601px) {
  .p-w-navbar,
  .overlay {
    width: 600px;
  }
  .p-navbar {
    z-index: 10;

    height: var(--navbar-height);
    border-bottom: 1px solid transparent;
    background-color: white;
    position: fixed;
  }
}
@media (max-width: 600px) {
  .p-navbar {
    z-index: 10;
    width: 100%;
    height: var(--navbar-height);
    border-bottom: 1px solid transparent;
    background-color: white;
    position: fixed;
  }
}
@media (min-width: 480px) {
  .overlay-content {
    margin-left: 56px;
  }
  .link-element {
    display: block;
    width: 89px;
  }
}
</style>
