<template>
  <div>
    <v-img
      eager
      @click="onClick"
      :src="require(`@/assets/images/${url}.svg`)"
      :height="height"
      :width="width"
    >
    </v-img>
  </div>
</template>
<script>
export default {
  props: {
    url: String,
    width: String,
    height: String,
  },
  methods: {
    onMouseOver() {
      this.$emit("mouse:over");
    },
    onMouseLeave() {
      this.$emit("mouse:leave");
    },
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style></style>
