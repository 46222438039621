import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {},
  plugins: [createPersistedState()],

  state: {
    selectedComponent: null,
    userinfo: {
      userPid: 0,
    },

    apiAccessToken: "",
    apiRefreshToken: "",

    isLogin: false,

    isDebug: true,

    isMobile: false,

    headerSubTitleId: "userlist_partner",
    tempVar: 0,
    tempTitle: "",
    factoryInfo: {},
    partnerInfo: {},
    clickedItem: null,
  },

  mutations: {
    setSelectedComponent(state, component) {
      state.selectedComponent = component;
    },
    setLogin(state, flag) {
      state.isLogin = flag;
    },
    setUserPid(state, pid) {
      state.userinfo.userPid = pid;
    },
    setAccessToken(state, token) {
      state.apiAccessToken = token;
    },
    setRefreshToken(state, token) {
      state.apiRefreshToken = token;
    },
    setHeaderSubTitleId(state, id) {
      state.headerSubTitleId = id;
    },
    setTempVar(state, value) {
      state.tempVar = value;
    },
    setTempTitle(state, value) {
      state.tempTitle = value;
    },
    setFactoryInfo(state, value) {
      state.factoryInfo = value;
    },
    setPartnerInfo(state, value) {
      state.partnerInfo = value;
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setClickedItem(state, e) {
      state.clickedItem = e;
    },
  },

  getters: { clickedItem: (state) => state.clickedItem },

  actions: {
    setClickedItem({ commit }, e) {
      commit("setClickedItem", e);
    },
  },
});

export default store;
